import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const fetchQuestionnaire = (survey: string, questionnaire: string) =>
  useApi(http.get(`/surveys/${survey}/questionnaires/${questionnaire}/pages`));

export const getSurveyEnrollments = (survey, user) => {
  return useApi(
    http.get(`/surveys/${survey}/enrollments`, { params: { user } }),
  );
};

export const getQuestionnaireSubmissions = (survey, questionnaire, student) => {
  return useApi(
    http.get(`/surveys/${survey}/submissions`, {
      params: { student, questionnaire },
    }),
  );
};
